.payment-method { // label
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  img {
    margin: 0 80px 0 30px;
  }
}
