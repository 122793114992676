.table {
  margin-bottom: 2rem;
  width: 100%;
  font-size: 1rem;

  th, td {
    border-bottom: 1px solid $color-site-background;
    padding: 0.5em 1em;
    background: $color-white;
    vertical-align: top;
  }
  th {
    text-align: left;
    text-transform: uppercase;
    font-weight: normal;
  }

  thead th {
    border-bottom-width: 3px;
  }
}
