.page { // body
  min-height: 100vh;
  display: flex;
  flex-flow: column;
}

.main {
  flex: 1;

  &--push {
    padding-bottom: 80px;
  }
  &--centered {
    .container:not(.container--full) {
      margin-left: auto;
      margin-right: auto;
      max-width: 720px;
    }
  }
}

.container {
  padding: 0 15px;
  width: 100%;

  &--align-center {
    text-align: center;
  }

  @at-root main > &--push-last:last-child {
    margin-bottom: 80px;
  }

  @include media(">=tablet") {
    &--narrow {
      margin: 0 auto;
      max-width: 80vw;
    }
  }

  @include media(">=tablet-landscape") {
    &--narrow {
      max-width: 60vw;
    }
  }

  @include media(">=1200px") {
    margin-left: auto;
    margin-right: auto;
    padding: 0;
    max-width: 1160px;

    &--narrow {
      max-width: 50vw;
    }
  }

  &--white {
    overflow: hidden;
    background-color: $color-white;
    max-width: none;
  }
}

.page-title {
  &__breadcrumbs {
    margin: 20px 0;
    font-size: $font-size-smaller;
    color: $color-lynch;

    a {
      color: inherit;
      text-decoration: none;

      &:focus, &:hover {
        text-decoration: underline;
      }
    }
  }

  &--large &__title {
    margin-top: 0;
    text-transform: uppercase;
    font-size: $font-size-giant;
    letter-spacing: 2px;
  }
}
