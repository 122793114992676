@import "~include-media/dist/include-media";

/// Override list styles with optional overrides for values that would be 0 or none by default.
@mixin list($margin: 0, $padding: 0, $list-style: none) {
  margin: $margin;
  padding: $padding;
  list-style: $list-style;
}

/// Visually hide the element while still remaining accessible for, for example, screen readers
@mixin accessible-hidden {
  position: absolute;
  border: 0;
  margin: -1px;
  clip: rect(0, 0, 0, 0);
  width: 1px;
  height: 1px;
  overflow: hidden;
  padding: 0;
}

/// include an icon in a pseudo element
@mixin pseudo-icon($icon: none, $where: before) {
  &::#{$where} {
    @include pseudo-icon-props($icon: $icon);
  }
}

/// props for an icon
@mixin pseudo-icon-props($icon: none, $font-size: 1em, $line-height: 1) {
  font: normal #{$font-size}/#{$line-height} $font-family-icons;
  text-transform: none;
  @if ($icon != none) {
    content: $icon;
  }
}
