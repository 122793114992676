.editor-container { // main
  height: calc(100vh - 50px);
  display: flex;
  flex-flow: column;
}

.editor {
  flex: 1;
  position: relative;
  display: flex;
  //min-height: calc(100vh - 124px);
  flex-flow: column;
  align-items: stretch;
  background: $color-shuttle-gray;

  &--overlay {
    background: transparent;
  }

  &--overlay &__panel {
    box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.2);
  }

  h2, h3 {
    text-transform: uppercase;
  }
  h2 {
    margin: 0;
    font-size: $font-size-large;
  }
  h4 {
    margin: 0;

    & + p {
      margin-top: 0;
    }
  }

  // Search
  &__search-form {
    flex: 1;
    align-self: stretch;
    background-color: $color-athens-gray;
  }
  &__search-field {
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
    background: $color-white;
    margin: 0;
    padding: 0;

    .container {
      display: flex;
      align-items: center;
    }

    label {
      display: flex;
      overflow: hidden;
      white-space: nowrap;
      max-width: 2em;
      color: $color-malachite;

      &::before {
        @include pseudo-icon-props($icon-search);
        padding-right: 1em;
        font-size: $font-size-large;
      }
    }
    input[type] {
      outline: none;
      border: 0;
      padding-left: 0;

      &::placeholder {
        text-transform: uppercase;
        color: $color-geyser;
      }
    }
  }
  &__search-results {
    overflow: hidden;
    border-top: 1px solid $color-light-border;
    padding: 10px 0 30px 30px;
    background: $color-white;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
  }
  &__search-no-results {
    margin: 50px 0;
  }
  &__search-footer {
    text-align: center;
  }
  &__search-placeholder {
    margin: 2rem 0;
  }

  &__pagination {
    text-transform: uppercase;
    text-align: center;

    @at-root .js &-prev {
      @include accessible-hidden;
    }
    &-next {

    }
  }

  &__preview {
    position: relative;
    background: $color-mystic;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 30px;

    img {
      width: auto;
      height: auto;
      max-width: 100%;
      //max-height: 100%;
      max-height: calc(100vh - #{60px + 65px + 60px}); // header+propositions+padding
      box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
    }
  }

  &__loader { // svg
    position: absolute;
    top: 20px;
    right: 20px;
    color: $color-spring-green;
  }

  // Panels
  &__panel {
    background: $color-athens-gray;
    padding: 30px 30px 0;
    flex: 1;
    display: flex;
    flex-flow: column;

    details {
      margin-bottom: 1rem;
    }
  }
  &__panel-section {
    margin: 0 0 30px;

    &:last-child {
      margin-bottom: 0;
    }

    &--full {
      margin-left: -30px;
      margin-right: -30px;
    }
  }
  &__panel-buttons {
    margin-top: auto;
  }

  // Tabs
  &__tabs {
    @include list;
    display: flex;
    text-transform: uppercase;

    * {
      display: flex;
      flex: 1;
      justify-content: center;
    }
  }
  &__tab {
    &-link {
      position: relative;
      background: $color-mystic;
      transition: background-color 0.1s ease-out;
      text-align: center;
      text-decoration: none;
      color: inherit;
      align-items: center;

      &::after {
        position: absolute;
        right: 1rem;
        font-size: 1.5rem;
        font-weight: bold;
      }
    }

    &--active &-link {
      background: $color-alabaster;

      &::after {
        content: "×";
      }
    }
  }

  // Form
  &__form-fields {
    display: flex;
    flex-flow: row wrap;
  }
  &__form-field {
    flex: 1;

    &--small {
      flex: 1 1 50%;

      &:nth-child(odd):not(:last-child) {
        padding-right: 5px;
      }
      &:nth-child(even):not(:first-child) {
        padding-left: 5px;
      }
    }
  }

  &__submit {
    margin-top: auto;
    border-radius: 0;
    justify-content: flex-start;
    width: 100%;
    padding: 20px;
    text-align: left;

    &--cart {
      &::before {
        @include pseudo-icon-props($icon-cart);
        margin-right: 15px;
      }
    }
  }

  @include media("<tablet-landscape") {
    &__panel {
      position: absolute;
      bottom: 0;
      width: 100%;
      max-height: calc(100vh - 50px);
      padding-top: 0;
      box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
      overflow-y: auto;
    }

    &__group {
      display: none;
      padding: 30px 0;
      max-height: 50vh;
      overflow-y: auto;

      &:target, &--active {
        display: block;
        position: relative;
      }

      h2 {
        margin-top: 0;
      }
    }

    &__form-footer {
      display: flex;
      margin: 0 -30px;
    }
    &__panel-buttons {
      margin: 0;
    }

    &__tabs {
      flex: 1;
    }

    &__tab-toggle {
      position: absolute;
      bottom: 100%;
      right: 0;
      width: 50px;
      height: 50px;
      background-color: $color-alabaster;

      a {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: hidden;
        text-indent:  100%;
        display: block;
        color: inherit;

        &::before {
          position: absolute;
          left: 50%;
          top: 50%;
          border: solid currentColor;
          border-width: 0 2px 2px 0;
          width: 8px;
          height: 8px;
          transform: translate(-50%, -50%) rotate(-135deg);
          content: "";
        }

        &:last-child {
          display: none;

          &::before {
            transform: translate(-50%, -50%) rotate(45deg);
          }
        }
      }

      @at-root .editor__tab--active ~ & {
        a:first-child {
          display: none;
        }
        a:last-child {
          display: block;
        }
      }
    }
  }

  @include media(">=tablet") {
    &, &__panel {
      overflow: auto;
    }

    &__preview {
      align-items: center;
    }
  //  align-items: flex-end;
  //  justify-items: stretch;
  //
  //  &__search-results {
  //    padding: 30px 0;
  //  }
  //
  //  &__panel {
  //    &--chooser {
  //      max-width: 60%;
  //    }
  //  }
  }
}

@include media(">=tablet-landscape") {
  .editor-container {
    height: calc(100vh - 60px);
  }

  .editor {
    flex-flow: row;
    justify-content: flex-end;
    align-items: stretch;

    &__panel {
      max-width: 37.5%;
    }
    &--overlay &__panel {
      padding-top: 50px;
    }

    &__tabs {
      display: none;
    }

    &__form-footer {
      margin-top: auto;
    }
  }
}
