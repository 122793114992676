.site-menu {
  text-transform: uppercase;
  align-items: stretch;

  &__list, &__sub-list, &__user-list {
    @include list;
  }

  &__list {
    outline: none;
    display: flex;
    flex-flow: column;
  }

  &__item {
    display: flex;
    position: relative;

    a {
      outline: none;
      display: inherit;
      align-items: center;
    }

    &--highlight > a {
      color: $color-spring-green;
    }
  }

  &__user-list {
    display: flex;
  }
  &__user-list > &__item {
    margin-left: 15px;
    padding: 0;
  }

  &__icon {
    font-size: 2em;

    &--account::before {
      content: $icon-account;
    }
    &--cart::before {
      content: $icon-cart;
    }
  }
  //&__item--active &__icon .icon__count {
  //  background-color: $color-white;
  //}

  // any submenu (outside main menu)
  &__sub-list {
    position: absolute;
    top: 100%;
    right: 200vw;
    z-index: 1;
    background: $color-lynch;
    white-space: nowrap;

    @at-root a:hover + &, a:focus + &, &:hover, &:focus, &:focus-within {
      right: auto;
    }

    a {
      flex: 1;
      padding: 5px 15px;

      &:hover {
        background-color: $color-bali-hai;
      }
    }
  }

  @include media("<tablet-landscape") {
    &__list {
      position: fixed;
      top: 50px; // height of .site-header__container
      right: 100%;
      z-index: 1;
      width: calc(100vw - 30px);
      background: $color-lynch;
      padding: 15px 0;
      transition: $transition-fast;

      &:focus,
      &:focus-within,
      &:target {
        transform: translateX(100%);
      }

    }
    // inside main menu sub menu's are just static on mobile
    &__list &__sub-list {
      position: static;
    }

    a {
      padding: 10px 15px;

      &.site-menu__icon {
        padding: 0;
      }
    }
    &__list &__sub-list a {
      padding-left: 30px;
    }
    &__item {
      flex-flow: column;

      &--active a:not(.site-menu__icon),
      a:not(.site-menu__icon):hover,
      a:not(.site-menu__icon):focus {
        background-color: $color-bali-hai;
      }
    }
  }

  @include media(">=tablet-landscape") {
    font-size: $font-size-smaller;

    &__list {
      flex-flow: row;

      > li {
        margin-left: 15px;
      }
    }

    // top level menu items get an underline:
    &__list > &__item {
      & > a {
        position: relative;
        &::after {
          position: absolute;
          bottom: 0;
          width: 100%;
          border-bottom: 3px solid transparent;
          transition: border-color $transition-fast;
          content: "";
        }
      }
      &:hover > a,
      &:focus-within > a,
      & > a:hover,
      & > a:focus {
        &::after {
          border-color: $color-fiord;
        }
      }

      &--active > a,
      &--ancestor > a {
        &::after {
          border-color: $color-lynch;
        }
      }

      &--has-children {
        &:hover > a,
        &:focus-within > a,
        & > a:hover,
        & > a:focus {
          &::after {
            border-color: $color-lynch;
          }
        }
      }
    }

    //&__sub-list {
    //  position: absolute;
    //  top: 100%;
    //  right: 200vw;
    //  background: $color-lynch;
    //  white-space: nowrap;
    //
    //  @at-root a:hover + &, a:focus + &, &:hover, &:focus, &:focus-within {
    //    right: auto;
    //  }
    //
    //  a {
    //    flex: 1;
    //    padding: 5px 15px;
    //
    //    &:hover {
    //      background-color: $color-bali-hai;
    //    }
    //  }
    //}
  }
}
