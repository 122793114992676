@use "sass:math";

$breakpoints: (
  "mobile-landscape": 480px,
  "tablet": 768px,
  "tablet-landscape": 1024px,
  "desktop": 1280px,
  "large": 1440px,
  "huge": 1920px
);

// Colors
$color-black: #000;
$color-blue-ribbon: #2862FF;

$color-malachite: #01C853;
$color-spring-green: #00E576;
$color-turquoise-green: #69F0AE;
$color-magic-mint: #B9F6CA;

$color-monza: #D60021;

$color-outer-space: #253238;
$color-fiord: #445A65;  // 576D7C
$color-lynch: #607D8B;
$color-bali-hai: #90A4AD;
$color-geyser: #CFD8DD;
$color-mystic: #EAEFF2;
$color-athens-gray: #F9FAFB;  // EBEFF2
$color-alabaster: #FAFAFA;

// Grays
$color-shuttle-gray: #576267;
$color-edward: #ACB0B0;
$color-porcelain: #F5F6F7;
$color-white: #FFF;

// Colors applied:
$color-site-background: $color-porcelain;
$color-default-text: $color-outer-space;
$color-default-link: $color-blue-ribbon;

$color-menu-link: $color-white;
$color-menu-link-focus: $color-spring-green;

$color-light-border: $color-porcelain;
$color-lighter-border: $color-edward;
$color-dark-border: $color-bali-hai;
$color-lighter-text: $color-shuttle-gray;
$color-light-text: $color-edward;

$font-size-base: 16;

$font-size-small: math.div(12, $font-size-base)  * 1rem;
$font-size-smaller: math.div(14, $font-size-base)  * 1rem;
$font-size-normal: 1rem;
$font-size-normal-px: $font-size-base * 1px;
$font-size-larger: math.div(18, $font-size-base)  * 1rem;
$font-size-large: math.div(24, $font-size-base)  * 1rem;
$font-size-big: math.div(28, $font-size-base)  * 1rem;
$font-size-huge: math.div(32, $font-size-base)  * 1rem;
$font-size-giant: math.div(48, $font-size-base) * 1rem;

$line-height-normal: 1.5;

$font-family-default: "Quicksand", sans-serif;
$font-family-icons: "sportymaps";

// transitions
$transition-fast: 0.2s ease-in-out;
$transition-slow: 0.5s ease-in-out;

$basicLightbox__background: rgba(0, 0, 0, 0.8);
$basicLightbox__zIndex: 1000;
$basicLightbox__duration: 0.4s;
$basicLightbox__timing: ease;
