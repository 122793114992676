@font-face {
  font-family: 'sportymaps';
  src:
    url('/static/sportymaps/fonts/sportymaps.ttf?i4mg5t') format('truetype'),
    url('/static/sportymaps/fonts/sportymaps.woff?i4mg5t') format('woff'),
    url('/static/sportymaps/fonts/sportymaps.svg?i4mg5t#sportymaps') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
