@keyframes disappear {
  from {
    max-height: 100px;
  }
  to {
    margin: 0;
    max-height: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.messages {
  &--fixed {
    position: fixed;
    top: 60px;
    width: 100%;
    z-index: 1;
    display: flex;
    flex-flow: column;
    align-items: center;
  }

  &--fixed &__message {
    max-width: 80%;
  }

  &__message {
    border-radius: 3px;
    background-color: $color-mystic;
    padding: 10px 20px;
    animation: $transition-slow 3s both disappear;
    overflow: hidden;
  }
}
