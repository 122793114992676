.cookie-notice {
  position: fixed;
  bottom: 1em;
  left: 1em;
  margin: 0;
  //max-width: 275px;
  padding: 2em;
  background: $color-black;
  transition: opacity 0.3s ease-in-out;
  color: $color-white;

  &--hidden {
    opacity: 0;
  }

  &__choices {
    display: flex;
    gap: 1em;
  }

  label {
    flex: 1;
    border-radius: 0.5em;
    padding: 0.5em;
    white-space: nowrap;
    text-align: center;
    font-weight: bold;
    cursor: pointer;

    &:last-child {
      background: $color-white;
      color: $color-black;
    }
  }
  input {
    @include accessible-hidden;
  }
  a {
    color: inherit;
  }

  .js & button {
    @include accessible-hidden;
  }
}
