.orders-list { // dl
  margin: 0;
  padding: 0;
  font-size: $font-size-smaller;

  &__order { // div
    margin-bottom: 1rem;
    padding-bottom: 1rem;

    &:not(:last-child) {
      border-bottom: 1px solid $color-lighter-border;
    }
  }
  &__title { // dt
    margin-bottom: 0.5rem;
  }
  &__line { // dd
    margin: 0 0 1rem;
  }
}
