@keyframes rotate {
  0% {
    transform-origin: 50% 50%;
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    stroke-dasharray: 1px, 200px;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 100px, 200px;
    stroke-dashoffset: -15px;
  }
  100% {
    stroke-dasharray: 100px, 200px;
    stroke-dashoffset: -125px;
  }
}
.spinner {
  animation: rotate 1.4s linear infinite;
  transition: opacity 0.3s ease-in-out;

  &__circle {
    animation: spinner 1.4s ease-in-out infinite;
    stroke-dasharray: 80px, 200px;
    stroke-dashoffset: 0;
    stroke: currentColor;
  }

  &--hidden {
    opacity: 0;
  }
}
