.image {
  margin: 0;
  padding: 0;
  display: flex;

  &[class*="image--show-"] {
    // hide, then selectors in media queries will show it again
    display: none;
  }
  @include media(">=tablet") {
    &--show-tablet[class] {
      display: flex;
    }
  }
}
