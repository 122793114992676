.check-list {
  @include list;

  & > li {
    &::before {
      @include pseudo-icon-props($icon-check, $font-size: 1.3rem);
      margin-right: 7px;
      color: $color-malachite;
    }
  }

  & > &__item {
    display: flex;
    align-items: baseline;

    &--checked::before {
      color: $color-malachite;
    }
  }

  &--row {
    display: flex;
    flex-flow: row wrap;
    padding-bottom: 20px;

    & > li {
      flex: 1;
      margin: 20px 10px 0;
      text-align: center;

      //&:last-child {
      //  margin-bottom: 20px;
      //}
    }
  }

  &--unchecked > li::before {
    color: $color-geyser;
  }

  @include media(">=tablet") {
    &--row {
      flex-flow: row;
      justify-content: center;

      & > li {
        flex: 0;
        margin: 20px 0;
      }

      & > li + li {
        margin-left: 30px;
      }
    }
  }
}
