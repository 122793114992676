.quote {
  margin-bottom: 1rem;
  overflow: hidden;
  background: $color-lynch;
  color: $color-white;

  blockquote {
    margin: 0;
    overflow: hidden;
    font-weight: bolder;
    font-size: $font-size-huge;
    letter-spacing: 1.5px;
    text-align: center;
  }
  p {
    margin: 1rem 0;
  }
}
