.posters {
  margin: 60px 0;
  text-align: center;

  &__title {
    margin: 0.5em 0;
  }
  &__list {
    justify-content: center;
  }
}
