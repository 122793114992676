.hero-image { // figure
  margin: 0;
  //max-width: 1900px;
  min-height: 30vh;
  //height: 65vh;
  position: relative;

  &__image {
    //object-fit: cover;
    //object-position: center center;
    //position: absolute;
    //top: 0;
    //left: 0;
    width: 100%;
    //height: 100%;
  }
}
