.poster-preview {
  margin: 2em 0;
  text-align: center;

  &__image {
    max-width: 100%;
    height: auto;
  }

  &__caption {
    margin: 1em 0;
  }
}
