.cta {
  overflow: hidden;
  padding: 30px 0;

  &__text {
    font-weight: bolder;
    font-size: $font-size-normal;
    letter-spacing: 1.5px;
    text-align: center;

    & > :first-child {
      margin-top: 0;
    }
  }

  &--primary {
    background: $color-lynch;
    color: $color-white;
  }

  &--secondary {
    background: $color-spring-green;
    color: $color-black;
  }

  &__buttons {
    margin: 0;
    text-align: center;
  }

  @include media(">=tablet") {
    &__text {
      font-size: $font-size-huge;
    }
  }
}
