.site-footer {
  margin-block-start: auto;

  &__top {
    background: $color-fiord;
    padding-bottom: 40px;
    color: $color-white;

    & + & {
      border-top: 1px solid $color-bali-hai;
    }
  }

  &__container {
    display: flex;
    flex-flow: column;

    &--centered {
      flex-flow: column;
      text-align: center;
    }
  }

  &__address {
    p {
      white-space: pre-line;
    }
  }

  &__socials {
    margin: 0;
    justify-content: center;
  }

  &__payment-providers {
    margin-top: 1.5rem;
    justify-content: center;

    > :not(:first-child)::before {
      content: " ";
    }
  }

  h2, h3 {
    text-transform: uppercase;
    font-size: $font-size-larger;
  }

  a {
    color: $color-white;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  &__menu3 {
    margin-top: 2rem;
    justify-content: center;
    a {
      color: inherit;
    }
  }

  &__copyright {
    margin: 1rem 0 2rem;
    font-size: $font-size-smaller;
    text-align: center;
  }

  @include media(">=tablet") {
    &__container {
      flex-flow: row;

      &--centered {
        flex-flow: column;
      }
    }
    &__menu {
      flex-basis: 30%;
    }
    &__address {
      flex-basis: 40%;
    }
  }
}

.footer-menu {
  &__list {
    @include list;
  }

  a {
    color: $color-white;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.site-propositions {
  background: $color-white;
  white-space: nowrap;
  padding: 10px 0 0;
  font-size: $font-size-smaller;
  color: $color-default-text;

  & > &__item {
    margin-top: 0;
    margin-bottom: 10px;
    align-items: stretch;
    justify-content: center;
  }

  a {
    text-decoration: none;
    color: inherit;

    &:hover {
      text-decoration: underline;
    }
  }
  @include media("<tablet") {
    &--tablet {
      @include accessible-hidden;
    }
  }
}
