.site-header {
  background: $color-outer-space;
  position: relative;
  z-index: 1;

  &__container {
    min-height: 50px;
    display: flex;
    font-weight: bolder;
    color: $color-white;

    > * {
      display: flex;
      align-items: center;
    }
  }

  a {
    outline: none;
    color: inherit;
    text-decoration: none;
  }

  &__title {
    margin: 0;
    overflow: hidden;
    background: center center/100% url("/static/sportymaps/img/logo-white.svg") no-repeat;
    font-size: $font-size-normal-px;
    font-weight: bold;
    letter-spacing: 2px;
    text-transform: uppercase;
    text-indent: 100%;
  }

  &__menu-toggle {
    display: inherit;
    order: -1;
    outline: none;
    margin-right: 15px;
    font-size: 1.5em;

    &::before {
      content: $icon-menu;
    }
  }

  &__menu {
    margin: 0 15px 0 auto;
  }

  &__back, &__help {
    display: inline-block;
    position: relative;
    overflow: hidden;
    width: 1em;
    white-space: nowrap;
    padding-left: 1em;
    font-size: 1.5em;
    line-height: 1;

    &::before {
      @include pseudo-icon-props($icon-arrow-back);
      position: absolute;
      left: 0;
    }
  }

  &__back {
    order: -1;
  }

  &__help::before {
    content: $icon-help;
  }

  &__title, &__menu-toggle, &__back, &__help {
    transition: color $transition-fast;

    &:hover, &:focus {
      color: $color-spring-green;
    }
  }

  &--modal &__container {
    justify-content: space-between;
    align-items: center;
  }

  @include media(">=tablet-landscape") {
    &__container {
      min-height: 60px;
    }
    &__menu-toggle {
      display: none;
    }
  }
}
