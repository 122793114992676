.cart {
  &__item {
    clear: both;
    margin: 0 0 30px;
    border-width: 0;
    border-bottom: 1px solid $color-bali-hai;
    padding: 0 0 30px;
    display: block;
    overflow: hidden;

    &--single {
      margin-bottom: 0;
      border: 0;
    }

    &-title {
      margin: 0 0 1em;
      text-transform: none;
      font-size: $font-size-larger;
    }
    &-actions {
      float: right;
      margin: 0;
      padding: 0;
    }
  }

  &__delete {
    appearance: none;
    border: 0;
    padding: 0;
    background: svg-data-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 23"><path fill="#253238" fill-rule="evenodd" d="M15.516 3.984h3.469v2.016h-13.969v-2.016h3.469l1.031-0.984h4.969zM8.016 9v9.984h7.969v-9.984h-7.969zM6 18.984v-12h12v12q0 0.797-0.609 1.406t-1.406 0.609h-7.969q-0.797 0-1.406-0.609t-0.609-1.406z"></path></svg>') no-repeat center center;
    width: 1.4em;
    height: 1.4em;
    font: inherit;
    text-indent: 2em;
    cursor: pointer;
  }

  @at-root .js &__update-button {
    @include accessible-hidden;
  }

  &__preview {
    float: left;
    margin: 0 30px 0 0;
    width: 225px;
    max-width: 30vw;
    padding: 0;

    &-image {
      width: 100%;
      height: auto;
      box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, 0.1);
    }
    &-caption {
      display: block;
      text-align: center;
    }
  }

  &__details {
    margin: 0 0 20px;
    display: flex;
    flex-flow: row wrap;
    align-items: center;

    dt, dd {
      flex: 1 50%;
      margin: 0 0 5px;
    }
    dt {
      flex-basis: 40%;
      padding-right: 10px;
    }
    dd {
      flex-basis: 60%;
    }
  }
  &__quantity-input {
    width: 75px;
    padding: 8px 15px;
  }

  &__item-subtotal {
    font-weight: bold;
  }

  &__section {
    padding: 30px 0;

    &--alternate {
      background: $color-mystic;
    }

    &-title {
      margin-bottom: 30px;
    }
  }

  &__subtotal {
    margin: 0;
    font-weight: bold;
    font-size: $font-size-larger;
    text-transform: uppercase;
  }

  &__note {
    margin: 0;
  }

  &__buttons {
    //background: $color-spring-green;
    //padding: 20px;
    display: flex;
    justify-content: space-between;
  }

  @include media("<tablet") {
    &__subtotal {
      display: flex;
      justify-content: space-between;
    }
    &__note {
      font-size: $font-size-smaller;
    }

    // floating footer:
    &__footer {
      overflow: hidden;

      &--sticky {
        position: fixed;
        background: $color-alabaster;
        box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
        left: 0;
        right: 0;
        bottom: 0;
      }
    }

    &__section--footer {
      margin: 10px 0;
      background-color: transparent;
      padding: 0;
    }
  }

  @include media(">=tablet") {
    &__subtotal {
      font-size: $font-size-huge;
    }
  }
}
