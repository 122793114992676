.button {
  appearance: none;
  outline: none;
  border: 0;
  border-radius: 5px;
  box-shadow: 0 0 7px 2px rgba(0, 0, 0, 0.1);
  height: 40px;
  background-color: $color-spring-green;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: bolder;
  font-size: $font-size-smaller;
  color: $color-black;
  transition: background-color $transition-fast;
  cursor: pointer;

  &:hover, &:focus {
    background-color: $color-turquoise-green;
  }

  &--secondary {
    background-color: $color-blue-ribbon;
    color: $color-white;

    &:hover, &:focus {
      background-color: lighten($color-blue-ribbon, 10%);
    }
  }

  &--full {
    width: 100%;
  }

  &--small {
    height: 40px;
    padding: 0 15px;
  }

  @include media("<tablet") {
    &[data-mobile-text] {
      overflow: hidden;
      //display: inline-block;
      white-space: nowrap;
      justify-content: flex-start;
      //
      &::before {
        margin-right: 100%;
        flex: 1 0 100%;
        text-align: center;
        content: attr(data-mobile-text);
      }
    }
  }
}

.buttons {
  .button + .button {
    margin-left: 10px;
  }
}

@include media(">=mobile-landscape") {
  .button {
    padding: 0 25px;
  }
}

@include media(">=tablet") {
  .button {
    height: 60px;
    padding: 0 45px;
    font-size: $font-size-normal;

    &--small {
      height: 40px;
      padding: 0 15px;
    }
  }

  .buttons {
    .button + .button {
      margin-left: 30px;
    }
  }
}
