.choice-list {
  border-top: 1px solid $color-mystic;
  @include list;
  font-size: $font-size-larger;

  &__option { // label
    border-bottom: 1px solid $color-mystic;
    padding: 25px;
    display: flex;
    align-items: flex-start;
    cursor: pointer;

    &::after {
      display: block;
      clear: both;
      content: "";
    }
  }

  &__preview {
    flex: 0 0 116px;
    margin-right: 30px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  }
  &__text {
    flex: 1;
  }

  input {
    @include accessible-hidden;

    &:checked + .choice-list__option {
      background: $color-mystic;
    }
  }
}
