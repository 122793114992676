.columns {
  &__column {
    margin: 30px 0;
  }

  @include media(">=tablet") {
    margin-left: -20px;
    margin-right: -20px;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    padding-top: 60px;
    font-size: $font-size-larger;

    &__column {
      margin: 0 20px 60px;
      flex: 1 1;
      //max-width: calc(50% - 20px);
      min-width: 30%;
      max-width: 50%;

      //&:first-child {
      //  margin-left: 0;
      //}
      //&:last-child {
      //  margin-right: 0;
      //}
    }
  }

  @include media(">=tablet-landscape") {
    &__column {
      flex: 1;
      max-width: none;
    }
  }
}
