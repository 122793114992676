.radio-list { // ul
  @include list;
  display: flex;
  flex-flow: row wrap;

  & > * {
    min-width: 25%;
    flex: 1;
    display: flex;
  }

  &__option { // label
    flex: 1;
    display: flex;
    margin: 0 10px 10px 0;
    border: 1px solid $color-dark-border;
    border-radius: 5px;
    padding: 5px 15px;
    font-weight: bolder;
    font-size: $font-size-smaller;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  input {
    @include accessible-hidden;
  }
  input:checked + &__option {
    border-color: $color-outer-space;
    background: $color-outer-space;
    color: $color-athens-gray;
  }
}
