.hidden,
.js .js-hidden {
  @include accessible-hidden;
}

.anchor-link {
  position: relative;
  text-decoration: inherit;
  color: inherit;

  &::before {
    position: absolute;
    right: 100%;
    margin-right: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 0.5em;
  }

  &:target::before {
    content: ">";
  }
  &:hover::before {
    content: "#";
  }
}

.richtext-image {
  max-width: 100%;

  &.full-width {
    width: 100%;
    height: auto;
  }
}

.small {
  font-size: $font-size-small;
}
