.faq-list {
  @include list;

  & > li {
    margin-bottom: 1em;
  }
}

.faq { // detail
  padding-left: 1em;

  &__summary {
    margin-left: -1em;
    outline: none;
    display: block;
    list-style: none;
    appearance: none;
    font-weight: bolder;
    transition: color $transition-fast;
    cursor: pointer;

    // ::marker is removed by list-style: none
    //&::marker, &::-webkit-details-marker {
    //  display: none;
    //}

    &::before {
      display: inline-block;
      width: 0.4em;
      height: 0.4em;
      margin-right: 0.5em;
      border: solid currentColor;
      border-width: 0 2px 2px 0;
      transform: translateY(-0.2em) rotate(45deg);
      transform-origin: center center;
      transition: transform $transition-fast;
      content: "";
    }

    &:focus {
      color: $color-default-link;
    }
    .faq[open] > &::before {
      transform: translateY(-0.2em) rotate(-45deg);
    }
  }
}
