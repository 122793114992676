.banner { // aside
  margin: 0;
  padding: 0;
  overflow: hidden;
  background: $color-blue-ribbon;
  color: $color-white;
  text-align: center;
  text-transform: uppercase;
  font-weight: bolder;
  letter-spacing: 1px;

  p {
    margin: 0.5em 0;
  }
}
