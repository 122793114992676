.foldout { // details
  background: $color-mystic;
  padding: 0 50px;
  font-size: $font-size-larger;

  &__toggle { // summary
    outline: none;
    padding: 10px 0;
    list-style: none;
    text-transform: uppercase;
    text-decoration: underline;
    font-weight: bold;
    color: $color-default-link;
    cursor: pointer;

    &:focus, &:hover {
      text-decoration: none;
    }

    // ::marker is removed by list-style: none
    //&::marker, &::-webkit-details-marker {
    //  display: none;
    //}

    &--icon {
      position: relative;

      &::before {
        @include pseudo-icon-props();
        position: absolute;
        right: 100%;
        margin: 2px 10px 0 0;
        font-size: 1.3em;
        color: $color-default-text;
      }
    }
    &--account::before {
      content: $icon-account;
    }
  }

  &[open] {
    padding-bottom: 30px;
  }
}
