.image-text {
  display: flex;
  flex-flow: column;

  &__image {
    margin: 0;
    flex: 1;
    display: flex;
    flex-flow: column;
  }
  &__img {
    max-width: 100%;
    width: auto;
    height: auto;
  }
  &__caption {
    font-style: italic;
  }

  @include media(">=tablet") {
    flex-flow: row;

    &__text {
      padding-left: 30px;
      flex: 1;
    }

    &--reversed {
      flex-flow: row-reverse;
    }
    &--reversed &__text {
      padding: 0 30px 0 0;
    }
  }
}
