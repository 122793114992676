.overlay {
  position: fixed;
  // hidden by default:
  right: 0;
  bottom: 0;
  z-index: 1;
  outline: 0;
  border: 0;
  overflow: hidden;
  display: flex;
  transition: 0.3s ease-in-out;
  transition-property: left, top;

  &::after {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    background-color: transparent;
    transition: background-color 0.3s ease-in-out;
  }

  &--visible::after {
    background-color: rgba($color-outer-space, 0.5)
  }

  @include media("<tablet-landscape") {
    top: 100%;
    left: 0;
    display: flex;
    flex-flow: column;

    &--visible {
      top: 0;
    }
  }
  @include media(">=tablet-landscape") {
    top: 0;
    left: 100%;

    &--visible {
      left: 0;
    }
  }
}
