.dialog {
  border: 0;
  border-radius: 5px;
  position: fixed;
  top: 50%;
  left: 50%;
  background: $color-alabaster;
  padding: 30px;
  max-width: 720px;
  width: 100%;
  transform: translate(-50%, -50%);

  &__title {
    margin: 0 50px 1em 0;
    text-transform: uppercase;
  }

  &__form {
    position: absolute;
    top: calc(30px + 0.6rem);
    right: 30px;
    margin: 0;
  }
  &__close {
    position: relative;
    appearance: none;
    outline: none;
    border: 0;
    overflow: hidden;
    width: 1.5em;
    height: 1.5em;
    background: none;
    padding: 0 0 0 1.5em;
    white-space: nowrap;
    font: inherit;
    color: inherit;
    transition: color $transition-fast;
    cursor: pointer;

    &:focus {
      color: $color-malachite;
    }

    &::before, &::after {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 1.3em;
      border-bottom: 2px solid currentColor;
      content: "";
    }
    &::before {
      transform: translateX(-50%) rotate(-45deg);
    }
    &::after {
      transform: translateX(-50%) rotate(45deg);
    }
  }
}
