.icon {
  &--only {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    width: 1em;
  }
  &--count {
    position: relative;
    width: 1.5em;

    &::before {
      margin-right: 1em;
    }
  }
  &--before {
    @include pseudo-icon();
  }
  &--after {
    @include pseudo-icon($where: after);
  }

  &__count {
    position: absolute;
    top: 6px;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 1.5em;
    height: 1.5em;
    background: $color-spring-green;
    font-size: $font-size-smaller;
    font-weight: bold;
    color: $color-black;
  }
}
