.poster-list {
  @include list;
  display: flex;
  flex-flow: column;
  align-items: stretch;

  &__item {
    margin-bottom: 1em;
    display: flex;
    flex-flow: column;
  }

  &__item-text {
    display: flex;
    flex-flow: column;
    margin-top: auto;
  }

  &__link {
    text-decoration: none;
    text-transform: uppercase;
    color: inherit;
  }

  &__title {
    margin-bottom: 0.5em;
    font-size: $font-size-large;

    &--small {
      font-size: $font-size-normal;
    }
  }

  &__preview {
    border: 0;
    margin-bottom: 1em;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
  }

  @include media("<tablet") {
    &__link {
      display: flex;
    }
    &__preview {
      margin-right: 20px;
    }
    &__name {
      margin-top: 10px;
    }
    &__preview, &__price {
      //display: none;
    }
  }

  @include media(">=tablet") {
    flex-flow: row wrap;
    //align-items: flex-start;
    //justify-content: center;

    &__item {
      flex: 0;
      margin: 0 30px 0 0;
      font-size: $font-size-smaller;
    }
    &__link {
      display: flex;
      flex-flow: column;
    }
    &__name {
      margin-bottom: 0.5em;
    }
    &__price {
      font-size: inherit;
      color: $color-shuttle-gray;
    }
  }
}
