input[type="email"],
input[type="password"],
input[type="tel"],
input[type="text"],
input[type="url"],
textarea,
select {
  border: 1px solid $color-bali-hai;
  border-radius: 5px;
  padding: 12px 20px;
  font-weight: bolder;
  font-size: $font-size-normal;
  width: 100%;
}

input::placeholder {
  text-transform: uppercase;
  color: $color-light-text;
}

.form {
  margin: 0;

  &__columns.columns {
    padding: 0;
  }

  &__column {
    padding: 20px 10px;

    &--inset {
      background-color: $color-white;
      padding: 20px 10px;
    }

    > h2:first-child {
      margin: 0 0 0.5em;
      text-transform: uppercase;
    }
  }

  &__fieldset {
    margin: 0;
    border: 0;
    padding: 0;
  }

  &__legend {
    margin: 0 0 0.5em;
    font-size: $font-size-larger;
    font-weight: bold;
    text-transform: uppercase;
  }

  &__row {
    display: flex;
  }
  &__row &__field {
    flex: 1;
    padding: 0 15px;

    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }

    &--shrink {
      flex: 0;
    }
  }

  &__field {
    margin-bottom: 1em;
    display: flex;
    flex-flow: column;

    &--invalid {
      input, select, textarea {
        border-color: $color-monza;
      }
    }

    &--checkbox, &--inline {
      flex-flow: row;
      align-items: baseline;
    }

    &--inline >  * {
      margin-right: 20px;

      &:last-child {
        margin-right: 0;
      }
    }

    &--checkbox {
      flex-wrap: wrap;

      input {
        order: -1;
        margin: 0 15px 0 0;
      }
      label {
        flex: 1 1 90%;
        text-transform: none;
        cursor: pointer;
      }
      .error-list {
        width: 100%;
      }
    }
  }

  &__label {
    margin-bottom: 0.3em;
    text-transform: uppercase;

    &::after {
      margin-left: 10px;
      font-size: $font-size-small;
    }

    @at-root .form__field--optional &[data-required-text]::after {
      content: "(" attr(data-required-text) ")"
    }

    &--large {
      font-size: $font-size-larger;
      font-weight: bold;
    }
  }

  &__help-text {
    margin: 0.1em 0;
    font-size: $font-size-smaller;
  }

  &__choices {
    @include list;

    & > li {
      display: flex;
      align-items: baseline;
      margin-bottom: 10px;
      padding-bottom: 10px;

      &:not(:last-child) {
        border-bottom: 1px solid $color-light-border;
      }
    }
    input {
      margin-right: 10px;
    }

    &--colors {
      margin: 0 -5px;
      display: flex;
      flex-flow: row wrap;

      li {
        margin: 0 5px;
      }

      input {
        @include accessible-hidden;
      }
    }
  }

  &__color-choice { // label
    display: block;
    padding: 0 5px 0.5em;
    text-transform: uppercase;
    font-size: $font-size-small;
    text-align: center;
    cursor: pointer;

    // text inside label
    span {
      display: block;
      padding: 0.35em 0.3em;
    }

    @at-root input:checked + & span {
      background-color: $color-black;
      color: $color-white;
    }
  }

  &__color-swatch {
    display: block;
    margin: 0 auto 0.3em;
    border: 1px solid $color-geyser;
    min-width: 60px;
    height: 60px;
    aspect-ratio: 1;
    background: no-repeat center center;
    background-size: contain;
    text-align: center;
    font-weight: normal;
    text-transform: uppercase;
    font-size: $font-size-small;

  }
  @at-root input:checked + &__color-choice &__color-swatch {
    border-color: $color-black;
  }

  &__fieldset-toggle {
    margin: 60px 10px 30px 0;

    &--normal {
      margin-top: 0;
    }

    &-label {
      font-weight: bolder;
      text-decoration: underline;
      text-transform: uppercase;
      color: $color-default-link;
      cursor: pointer;

      &:focus, &:hover {
        text-decoration: none;
      }

      &--normal {
        text-transform: none;
        text-decoration: none;
        font-weight: normal;
        color: inherit;
      }
    }

    &:not(:checked) + &-label + fieldset {
      @include accessible-hidden;
    }
  }

  &__buttons .button {
    margin-right: 20px;
  }

  &__values { // dl
    margin: 0;
    padding: 0;
  }
  &__value { // dd
    margin: 0;
  }

  @include media(">=tablet") {
    &__legend, &__label--large {
      font-size: $font-size-large;
    }

    &__field--read-only {
      flex-flow: row;
    }
    &__label--read-only { // dt
      margin: 0;
      flex: 1 1 50%;
    }
    &__value { // dd
      flex: 1 1 50%;
    }

    &__column {
      padding: 20px 0;

      &--inset {
        background-color: $color-white;
        padding: 20px 30px;
      }
    }
  }
}

.error-list {
  @include list;
  font-size: $font-size-smaller;
  color: $color-monza;
}
