.key-values { // dl
  &, dt, dd {
    margin: 0;
    padding: 0;
  }
  dt {
    padding-right: 0.5em;
  }
  &__row {
    display: flex;
  }
}
