* {
  box-sizing: border-box;
  font-family: inherit;
}

html {
  background-color: $color-site-background;
  font: #{$font-size-normal-px}/#{$line-height-normal} $font-family-default;
  letter-spacing: 0.2px;
  color: $color-default-text;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  padding: 0;
}

main {
  background: $color-athens-gray;
  overflow: hidden;
}

h1, h2, h3, h4, h5, h6 {
  margin: 1em 0 0.1em;
}

h1 {
  font-size: $font-size-huge;
  text-transform: uppercase;
}

h2 {
  font-size: $font-size-big;
}

h3 {
  font-size: $font-size-large;
}

p, ul, ol {
  margin-top: 0;
  margin-bottom: 1rem;
}

a {
  color: $color-blue-ribbon;
}

hr {
  margin: 1em 0;
  border: solid $color-bali-hai;
  border-width: 0 0 1px;
}

dialog {
  margin: 0;
}

dialog:not([open]) {
  display: none;
}

// Why a separate selector for ::backdrop AND + .backdrop? Safari doesn't render ANY
// backdrop when these selectors are combined.
dialog::backdrop {
  background: rgba($color-outer-space, 0.7);
  backdrop-filter: blur(1px);
}
dialog + .backdrop {
  background: rgba($color-outer-space, 0.7);
  backdrop-filter: blur(1px);
}
dialog + .backdrop,
._dialog_overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

details {
  summary {
    cursor: pointer;
  }
}

table {
  border: 0;
  border-collapse: collapse;
}
th {
  text-align: left;
}
